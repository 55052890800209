import React from 'react';
import { Translate } from '@hanssens/ht-translate';

const pageTitle = (props) => {
    return (
        <div id='global-content-header' className='row mb-3'>
            <div className='col'>
                <h5 className='mb-1 pb-0'>
                    <Translate id={props.title} data={props.data} />
                </h5>
                <span className='text-muted'>
                    <Translate id={props.subTitle} />
                </span>
            </div>
        </div>
    );
};

export default pageTitle;
