import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import withResize from 'ht-resize';

import AuthRoute from '../../components/Routers/AuthRoute';
import PrivateTenantRoute from '../../components/Routers/PrivateTenantRoute';
import AppSettingRoute from '../../components/Routers/AppSettingRoute';

// GENERAL
import PresenceManager from '../presence-manager/PresenceManager';

const Content = () => {
    return (
        <AuthRoute>
            <PrivateTenantRoute>
                <AppSettingRoute>
                    <Switch>
                        {/* General */}
                        <Route path='' component={PresenceManager} />

                        {/* REDIRECT */}
                        <Redirect to='/dashboard' />
                    </Switch>
                </AppSettingRoute>
            </PrivateTenantRoute>
        </AuthRoute>
    );
};

export default withResize()(Content);
