import React from 'react';
import { Route, Switch } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import Callback from './Callback';
import UnAuthorized from './UnAuthorized';

import App from '../App';

const AuthBeforeApp = () => {
    return (
        <Switch>
            <Route path='/callback' component={Callback} />
            <Route path='/logout' component={UnAuthorized} />
            <Route path='/unauthorized' component={UnAuthorized} />
            <PrivateRoute component={App} />
        </Switch>
    );
};

export default AuthBeforeApp;
