import React from 'react';
import Content from './Content';

import './ContentContainer.scss';

const ContentContainer = (props) => {
    // Set the base classes
    let classNames = ['content-container'];

    // Add left toggle
    if (props.leftToggled) {
        classNames.push('left-toggled');
    }

    // Render content
    return (
        <div id='content-container' className={classNames.join(' ')}>
            <Content />
        </div>
    );
};

export default ContentContainer;
