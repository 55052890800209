import React, { useState, useCallback } from 'react';
import { Translate, useTranslate } from '@hanssens/ht-translate';
import { Select, NoContent, ManageTable, sortOrder, Search, Input } from 'ht-gui';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import axios from 'axios';

import { CleanupURI } from '../../utilities/URI';
import notifyApi from '../../api/notifyApi';

import PageTitle from '../../components/Layout/Title/PageTitle';

import precenseManagerTranslations from '../../translations/presenceManager.json';
import Availability from '../../components/Availability/Availability';
import * as immutable from '../../utilities/immutable';

const debounceFunction = () => {
    return;
};

const debounce = AwesomeDebouncePromise(debounceFunction, 350);

const snackbarReset = { open: false, text: '', severity: 'info' };

let cancelTokenSource = axios.CancelToken.source();

const PresenceManager = (props) => {
    const [users, setUsers] = useState(null);
    const [snackbar, setSnackbar] = useState(snackbarReset);

    useTranslate(precenseManagerTranslations);

    /***********************************************************************
     * Managetable config
     ***********************************************************************/
    const config = {
        columns: {
            displayName: {
                label: <Translate id='presence.displayName' />,
            },
            department: {
                label: <Translate id='presence.department' />,
            },
            statusses: {
                label: <Translate id='presence.statusses' />,
            },
            personalMessage: {
                label: <Translate id='presence.personalMessage' />,
            },
            availability: {
                label: <Translate id='presence.availability' />,
            },
        },

        sort: {
            by: 'displayName',
            order: sortOrder.ASC,
        },

        hideSearch: true,
    };

    /***********************************************************************
     * Event handlers
     ***********************************************************************/
    const handleSearchChanged = async (value) => {
        await debounce();

        cancelTokenSource.cancel('Operation cancelled due to new request.');
        cancelTokenSource = axios.CancelToken.source();
        // If the value is set to an empty string
        // No users should be shown and no fetch should be performed
        if (value.trim() === '') {
            setUsers(null);
            return;
        }

        setUsers('loading');
        notifyApi()
            .get('userstatus/search/' + encodeURIComponent(CleanupURI(value)), { cancelToken: cancelTokenSource.token })
            .then((res) => {
                setUsers(res.data);
            })
            .catch((err) => {
                if (!notifyApi().isCancel(err)) {
                    setUsers('error');
                    displayErrorMessage('Fout tijdens het ophalen van gebruikers.');
                }
            });
    };

    const handlePersonalMessageChanged = useCallback(
        async (user, e) => {
            const value = e.target.value;
            const currentUser = { ...user };

            // get the current user
            // console.log(
            //     'index',
            //     users.findIndex((u) => u.contactId === user.contactId),
            //     user.contactId
            // );
            setUsers(
                immutable.updateObjectInArray(users, {
                    index: users.findIndex((u) => u.contactId === user.contactId),
                    item: { ...user, personalMessage: value },
                })
            );

            await debounce();

            notifyApi()
                .post('userstatus/personalmessage', {
                    contactId: currentUser.contactId,
                    message: value,
                })
                .then(() => {
                    displaySuccessMessage('Persoonlijk bericht opgeslagen voor ' + currentUser.displayName);
                })
                .catch((err) => {
                    displayErrorMessage('Fout tijdens het opslaan van het persoonlijk bericht voor ' + currentUser.displayName);
                });
        },
        [users]
    );

    const handleCurrentStatusChanged = useCallback(
        async (user, e) => {
            const value = e.target.value;
            const currentUser = { ...user };

            // Store the current status in case a rollback is required
            var currentStatus = user.statusses.find((status) => status.isActive);
            var newStatus = user.statusses.find((s) => s.id === value);

            // Update the user to reflect the new active status
            let statusses = immutable.updateObjectInArray(user.statusses, {
                index: user.statusses.findIndex((s) => s.isActive),
                item: { ...currentStatus, isActive: false },
            });

            statusses = immutable.updateObjectInArray(statusses, {
                index: statusses.findIndex((s) => s.id === value),
                item: { ...newStatus, isActive: true },
            });

            const updatedUser = { ...user, statusses: statusses };

            // Update the state
            setUsers(
                immutable.updateObjectInArray(users, {
                    index: users.findIndex((u) => u.contactId === user.contactId),
                    item: updatedUser,
                })
            );

            await debounce();

            notifyApi()
                .post('userstatus/status', {
                    contactId: currentUser.contactId,
                    statusId: value,
                })
                .then(() => {
                    displaySuccessMessage('Status opgeslagen voor ' + currentUser.displayName);
                })
                .catch((err) => {
                    /*setUsers([
                    ...users
                ])*/
                    displayErrorMessage('Fout tijdens het opslaan van de status voor ' + currentUser.displayName);
                });
        },
        [users]
    );

    const handleSnackbarClosed = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    /***********************************************************************
     * Render functions
     ***********************************************************************/
    const convertUserData = () => {
        // Run over all the users
        return users.map((user) => {
            let currentStatus = null;
            const statusses = user.statusses.map((status) => {
                // Check of this is the current status
                if (status.isActive) {
                    currentStatus = status.id;
                }

                // Create the status select object
                return {
                    value: status.id,
                    label: status.name,
                };
            });

            return {
                displayName: user.displayName,
                department: user.department,
                personalMessage: <Input label={<Translate id='presence.personalMessage' />} value={user.personalMessage} onChange={(value) => handlePersonalMessageChanged(user, value)} />,
                statusses: (
                    <Select
                        id={user.id + '-statusses'}
                        label={<Translate id='presence.statusses' />}
                        value={currentStatus}
                        options={statusses}
                        onChange={(value) => handleCurrentStatusChanged(user, value)}
                    />
                ),
                availability: <Availability index={user.availability} />,
            };
        });
    };

    const displaySuccessMessage = (message) => {
        setSnackbar({
            open: true,
            text: message,
            severity: 'success',
        });
    };

    const displayErrorMessage = (message) => {
        setSnackbar({
            open: true,
            text: message,
            severity: 'error',
        });
    };

    /***********************************************************************
     * Render
     ***********************************************************************/
    let content = null;

    if (users === null) {
        content = (
            <NoContent icon='fas fa-address-card fa-7x' title={<Translate id='presence.startSearch' />} description={<Translate id='presence.startSearchMessage' />} backgroundColour='bg-gray' />
        );
    } else if (users === 'loading') {
        content = <NoContent icon='fas fa-spinner-third fa-spin fa-7x' title={<Translate id='presence.loading' />} description={<Translate id='presence.loadingDesc' />} backgroundColour='bg-gray' />;
    } else if (users === 'error') {
        content = <NoContent icon='fas fa-times fa-7x' title={<Translate id='presence.error' />} description={<Translate id='presence.errorDesc' />} backgroundColour='bg-danger' />;
    } else if (users.length === 0) {
        content = <NoContent icon='fas fa-users-slash fa-7x' title={<Translate id='presence.noResults' />} description={<Translate id='presence.noResultsMessage' />} backgroundColour='bg-gray' />;
    } else {
        content = (
            <div className='row mt-3'>
                <div className='col-12'>
                    <ManageTable {...config} data={convertUserData()} />
                </div>
            </div>
        );
    }

    return (
        <section id='presence-manager' className='p-4 main-scrollable'>
            <PageTitle title='presence.title' subTitle='presence.subTitle' />
            <Search id='user-search' label={<Translate id='presence.username' />} onSearchChange={handleSearchChanged} />

            {content}

            <Snackbar open={snackbar.open} autoHideDuration={4000} onClose={handleSnackbarClosed} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleSnackbarClosed} severity={snackbar.severity}>
                    {snackbar.text}
                </Alert>
            </Snackbar>
        </section>
    );
};

export default PresenceManager;
