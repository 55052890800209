import React, { useEffect, useState } from 'react';
import { useStore } from 'ht-store';
import { Translate } from '@hanssens/ht-translate';

import Loading from '../Shared/Loading';
import Error from '../Shared/Error';

import { presenceAPISetup } from '../../configs/config';
import { default as rightsApi } from '../../api/rightsApi';
import { getConfig } from '../../configs/config';

const AppSettingRoute = ({ children }) => {
    /***********************************************************************
     * State
    /***********************************************************************/
    const [tenant] = useStore('tenant');
    const [appsetting, setAppSetting] = useStore('appSetting');

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const { HT_APPID } = getConfig();

    /***********************************************************************
     * Init
    /***********************************************************************/
    useEffect(() => {
        if (tenant.selectedTenant) {
            setLoading(true);
            setError(null);
            setAppSetting(null);

            rightsApi()
                .get(`v1/rights/tenantApplicationSetting/${HT_APPID}`)
                .then((response) => {
                    setAppSetting(response.data);
                })
                .catch((error) => {
                    console.log('Err', error);
                    setError(error.response.status);
                    setLoading(false);
                });
        }
    }, [tenant, setAppSetting, HT_APPID]);

    useEffect(() => {
        if (
            appsetting &&
            appsetting.apiBaseURI &&
            appsetting.additionalSettings.findIndex((s) => s.id === 'cd75c127-5870-43de-8192-9d2f92280b38') !== -1 &&
            appsetting.additionalSettings.findIndex((s) => s.id === '4be9fda1-3ce3-4515-abbc-9409dc5f0f06') !== -1
        ) {
            presenceAPISetup(
                appsetting.apiBaseURI,
                appsetting.additionalSettings.find((s) => s.id === '4be9fda1-3ce3-4515-abbc-9409dc5f0f06').value,
                appsetting.additionalSettings.find((s) => s.id === 'cd75c127-5870-43de-8192-9d2f92280b38').value
            );
            setLoading(false);
        } else if (appsetting) {
            setLoading(false);
            setError(404);
        }
    }, [appsetting]);

    /***********************************************************************
     * Render
    /***********************************************************************/

    if (error) {
        return <Error className='m-4' code={error} message={<Translate id='presence.appSetting.errorDesc' />} />;
    }

    if (loading) {
        return <Loading title={<Translate id='presence.appSetting.loading' />} description={<Translate id='presence.appSetting.loadingDesc' />} className='m-4' />;
    }

    return children;
};

export default AppSettingRoute;
