import React from 'react';
import { useTranslate } from '@hanssens/ht-translate';

import loginTranslations from '../../translations/auth/login.json';

const InitApp = ({ children }) => {
    useTranslate(loginTranslations);

    return <React.Fragment>{children}</React.Fragment>;
};

export default InitApp;
