import React from 'react';
import { useTranslate } from '@hanssens/ht-translate';
import { useStore } from 'ht-store';

// Import components
import SidebarLeftItems from '../../components/Layout/SidebarLeft/SidebarLeftItems';

// Import translation file
import sidebarLeftTranslations from '../../translations/sidebarLeft.json';

// Import css
import './SidebarLeft.scss';

// Import logo
import logo from '../../assets/images/Hanssens_beyondtelecom_white.svg';

const SidebarLeft = (props) => {
    /********************************/
    /* LOCAL STATE & PROPS
    /********************************/
    useTranslate(sidebarLeftTranslations);
    const [auth] = useStore('auth');

    /********************************/
    /* RENDER
    /********************************/
    // Set classnames
    let classNames = ['bg-dark-blue', 'text-white', 'br-sm', 'b-secondary'];
    if (props.toggled) {
        classNames.push('toggled');
    }

    // Define the links
    /*
     * Each array is basically a 'category'.
     * The items in a category are a 3-wide array:
     * - The first element is the link
     * - The second element is the name (or the translation key)
     * - The third element is the icon to display
     */
    let presenceLinks = [];

    if (auth && auth.rights && auth.rights.length > 0) {
        presenceLinks = [['', 'sidebarLeft.users', 'fas fa-users']];
    }
    /*const adminLinks = [
        ["/admin/manage", "sidebarLeft.manage", "fas fa-cog"]
    ];*/

    return (
        <div id='sidebar-left' className={classNames.join(' ')}>
            <div className='sidebar-left-container'>
                <ul className='nav nav-pils flex-column'>
                    <SidebarLeftItems first content={presenceLinks} handleLinkClicked={props.handleLinkClicked} title='sidebarLeft.presence' />
                    {/*<SidebarLeftItems content={adminLinks} handleLinkClicked={props.handleLinkClicked} title="sidebarLeft.manage" />*/}
                </ul>
            </div>

            <a href='https://hanssenstelecom.be/contact/' target='_blank' rel='noreferrer'>
                <div className='logo mt-auto w-100 d-flex pb-3 justify-content-center'>
                    <img className='img-fluid' src={logo} alt='hanssens' />
                </div>
            </a>
        </div>
    );
};

export default SidebarLeft;
