import React, { useState } from 'react';

import TopbarRight from '../../components/Layout/Topbar/TopbarRight';
import TopbarLeft from '../../components/Layout/Topbar/TopbarLeft';
import TopbarDropdown from '../../components/Layout/Topbar/TopbarDropdown';
import TopbarMiddle from '../../components/Layout/Topbar/TopBarMiddle';
import * as types from '../../components/Layout/Topbar/TopbarDropdown/DropdownTypes';

// Import scscs
import './Topbar.scss';

const Topbar = (props) => {
    /********************************/
    /* LOCAL STATE
    /********************************/
    const [dropdown, setDropdown] = useState(types.DROPDOWN_NONE);

    /********************************/
    /* FUNCTIONS 
    /********************************/
    const handleTopbarMenuClicked = (dropdownMenu) => {
        if (dropdown !== dropdownMenu) {
            setDropdown(dropdownMenu);

            if (!props.topbarToggled) {
                props.handleToggleTopbarClicked();
            }
        } else {
            props.handleToggleTopbarClicked();
        }
    };

    /********************************/
    /* RENDER 
    /********************************/
    return (
        <>
            {/* Topbar */}
            <nav id='topbar' className='navbar navbar-primary p-0 bb-xs fixed-top bg-white'>
                {/* Left side */}
                <TopbarLeft toggled={props.sidebarRightToggled} handleToggleLeftClicked={props.handleToggleLeftClicked} handleToggleRightClicked={props.handleToggleRightClicked} />

                {/* Middle content */}
                <div className='topbar-middle d-flex align-items-start'>
                    <TopbarMiddle />
                </div>

                {/* Right side */}
                <TopbarRight handleTopbarMenuClicked={handleTopbarMenuClicked} />
            </nav>

            {/* Dropdown middle content */}

            {/* Dropdown at the right side of topbar */}
            <TopbarDropdown toggled={props.topbarToggled} dropdown={dropdown} />
        </>
    );
};

export default Topbar;
