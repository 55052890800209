import React, { useState } from 'react';

import SidebarLeftItem from './SidebarLeftItem';
import SidebarLeftTitle from './SidebarLeftTitle';

const SidebarLeftItems = (props) => {
    /********************************/
    /* LOCAL STATE
    /********************************/
    const [toggled, setToggled] = useState(false);

    /********************************/
    /* RENDER 
    /********************************/
    // Create the items
    let items = props.content.map((item, index) => {
        return (
            <SidebarLeftItem 
                key={index} 
                link={item[0]} 
                name={item[1]} 
                icon={item[2]} 
                toggled={toggled} 
                handleLinkClicked={props.handleLinkClicked} />
        );
    });

    return (
        <>
            {/* Title */}
            <SidebarLeftTitle first={props.first ? true : false}
                handleTitleClicked={() => setToggled(!toggled)}
                title={props.title}
                toggled={toggled} />
            
            {/* Sub items */}
            {items}
        </>
    )
}

export default SidebarLeftItems;