import React from 'react';
import { NoContent } from 'ht-gui';

import { Translate } from '@hanssens/ht-translate';

const Unauthorized = ({ className }) => {
    return (
        <div className={className}>
            <NoContent title={<Translate id='presence.unauthorized' />} description={<Translate id='presence.unauthorizedDesc' />} icon='fas fa-cloud-exclamation fa-7x' backgroundColour='bg-danger' />
        </div>
    );
};

export default Unauthorized;
