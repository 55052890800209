import React, { useEffect, useCallback, useState } from 'react';
import { Select } from 'ht-gui';
import { useStore } from 'ht-store';
import { getClasses } from 'ht-resize';
import { useSelector } from 'react-redux';

import { Translate, useTranslate } from '@hanssens/ht-translate';

import { default as rightsApi } from '../../../api/rightsApi';
import { tenantIdChanged } from '../../../configs/config';

import topbarTranslations from '../../../translations/layout/topbar.json';

const TopbarMiddle = (props) => {
    /*********************************************************************/
    /* State
    /*********************************************************************/
    const user = useSelector((state) => state.oidc.user);
    const [tenant, setTenant] = useStore('tenant');
    const [auth, setAuth] = useStore('auth');

    const [tenants, setTenants] = useState(null);
    const [init, setInit] = useState(false);

    useTranslate(topbarTranslations);

    /*********************************************************************/
    /* Helper functions
    /*********************************************************************/
    const fetchRights = useCallback(() => {
        // Update the auth rights
        rightsApi()
            .get('v1/rights/auth', {
                headers: {
                    Authorization: 'Bearer ' + user.access_token,
                },
            })
            .then((resp) => {
                if (!resp.data.includes('presenceManager.access') && !resp.data.includes('*')) {
                    setAuth({ ...auth, rights: null, error: 'No access' });
                } else {
                    setAuth({ ...auth, rights: resp.data, isAuthenticated: true, error: null });
                }
            })
            .catch((err) => {
                setAuth({ ...auth, error: err });
            });
    }, [auth, setAuth, user]);

    const fetchTenants = useCallback(() => {
        rightsApi()
            .get('v1/rights/tenant')
            .then((response) => {
                setTenants(response.data);

                if (response.data.length === 1) {
                    setTenant({ ...tenant, selectedTenant: response.data[0].id });
                    tenantIdChanged(response.data[0].id);
                    fetchRights();
                }
                setInit(true);
            })
            .catch((error) => {});
    }, [tenant, setTenant, fetchRights]);

    /*********************************************************************/
    /* Event handlers
    /*********************************************************************/
    const handleSelectedTenantChanged = useCallback(
        (value) => {
            setTenant({ ...tenant, selectedTenant: value });
            setAuth({ ...auth, rights: null, error: null });
            tenantIdChanged(value);
            fetchRights();
        },
        [tenant, auth, setTenant, setAuth, fetchRights]
    );

    /*********************************************************************/
    /* Init
    /*********************************************************************/

    useEffect(() => {
        if (!init) {
            if (user) {
                fetchTenants();
            }
        }
    }, [fetchTenants, user, init]);

    /*********************************************************************/
    /* Render
    /*********************************************************************/

    let options = [];
    if (tenants) {
        options = tenants
            .sort((a, b) => {
                if (a.isActive === b.isActive) {
                    return 0;
                } else if (a.isActive) {
                    return -1;
                }
                return 1;
            })
            .map((t) => {
                let label = (
                    <>
                        <i className='fa-regular fa-plug pr-2' />
                        {t.name}
                    </>
                );
                if ('isActive' in t && !t.isActive) {
                    label = (
                        <div className='text-danger'>
                            <i className='fa-regular fa-plug-circle-xmark pr-2' />
                            {t.name}
                        </div>
                    );
                }
                return {
                    value: t.id,
                    label: label,
                };
            });
    }

    return (
        <div className='d-flex w-100'>
            <div className='row w-100'>
                <div className={getClasses({ shared: 'ml-2', sm: 'col-12', md: 'col-3' })}>
                    <Select
                        value={tenant?.selectedTenant}
                        options={options}
                        onChange={(e) => handleSelectedTenantChanged(e.target.value)}
                        label={<Translate id='presence.topbar.tenant' />}
                        id='tenant-select'
                        disabled={options.length <= 1}
                    />
                </div>
            </div>
        </div>
    );
};

export default TopbarMiddle;
