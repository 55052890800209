import React from 'react';

const Availability = (props) => {
    switch (props.index) {
        case 0: // Unknown
            return <i className='fas fa-question-circle fa-2x pt-2' />;
        case 1: //offline
            return <i className='fas fa-circle text-gray fa-2x pt-2' />;
        case 2: //Do not disturb
            return <i className='fas fa-minus-circle text-danger fa-2x pt-2' />;
        case 3: // Idle
            return <i className='fas fa-check-circle text-success fa-2x pt-2' />;
        case 4: // Ringing
            return <i className='fas fa-circle text-warning fa-2x pt-2' />;
        case 5: // INCall
            return <i className='fas fa-circle text-danger fa-2x pt-2' />;
        default:
            return <i className='fas fa-question-circle fa-2x pt-2' />;
    }
};

export default Availability;
