export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const updateObjectInArray = (array, action) => {
    return array.map((item, index) => {
        // This isn't the item we care about - keep it as-is
        if (index !== action.index) {
            return item
        }
  
        // Otherwise, this is the one we want - return an updated value
        return {
            ...item,
            ...action.item
        }
    });
}

export const removeItemFromArray = (array, index) => {
    return [...array.slice(0, index), ...array.slice(index + 1)];
}