import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { TranslateProvider } from '@hanssens/ht-translate';
import * as serviceWorker from './serviceWorker';
import { reducer as oidcReducer, loadUser, OidcProvider } from 'redux-oidc';
import { createStore, compose, combineReducers, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { configureStore } from 'ht-store';

import { initialiseConfig as initialisePresenceConfig } from './configs/config';

// Import user manager config
import userManager from './configs/userManager';

// Import root application
import AuthBeforeApp from './containers/Login/AuthBeforeApp';
import InitApp from './containers/Login/InitApp';

// Import css
import '@fortawesome/fontawesome-pro/css/all.css';
import './css/main.scss';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const initialState = {};

// Apply middleware
const enhancer = composeEnhancers(applyMiddleware(thunk));

// Combine reducers
const rootReducer = combineReducers({
    oidc: oidcReducer,
});

// Create the store
const store = createStore(rootReducer, initialState, enhancer);

// Setup the authentication store
loadUser(store, userManager);

initialisePresenceConfig();

// Create app
const app = (
    <Provider store={store}>
        <OidcProvider store={store} userManager={userManager}>
            <TranslateProvider languages={['en', 'nl']}>
                <BrowserRouter>
                    <InitApp>
                        <AuthBeforeApp />
                    </InitApp>
                </BrowserRouter>
            </TranslateProvider>
        </OidcProvider>
    </Provider>
);

configureStore({
    auth: {
        initialValue: {
            isAuthenticated: false,
            error: null,
        },
        persist: false,
    },
    tenant: {
        initialValue: {
            selectedTenant: null,
        },
        persist: true,
    },
    appSetting: {
        initialValue: null,
        persist: true,
    },
});

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
