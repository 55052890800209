import { createAxiosInstance as createNotifyApi } from '../api/notifyApi';
import { createAxiosInstance as createRightsApi } from '../api/rightsApi';

// Default configuration
const defaultConfiguration = {
    // Base props
    // RIGHTS_URI: 'https://localhost:44383/',
    RIGHTS_URI: 'https://api.hanssens.io/',
    OIDC_BASE_URI: 'https://login.hanssens.io',
    OIDC_CLIENT_ID: 'presence.manager.dev',
    HT_APPID: 'c5346c34-8c0c-45ee-86be-a836d3fe32ab',

    NOTIFY_CLIENTID: '',
    NOTIFY_SECRET: '',
};

// Actual configuration
let config = { ...defaultConfiguration };

// Get the configuration
export const getConfig = () => {
    return { ...config };
};

// Set the configuration
// this must be done at the index.js
export const initialiseConfig = (newConfig) => {
    // Update config
    config = {
        ...defaultConfiguration,
        ...newConfig,
    };

    // Change the base url with the new updated config
    createNotifyApi(config);
    createRightsApi(config);
};

export const tenantIdChanged = (tenantId) => {
    // Update config
    config = {
        ...config,
        SELECTED_HT_TENANTID: tenantId,
    };

    // Change the api with the new updated config
    createRightsApi(config);
};

export const presenceAPIChanged = (uri) => {
    // Update config
    config = {
        ...config,
        API_URI: uri,
    };

    // Change the api with the new updated config
    createNotifyApi(config);
};

export const presenceAPISetup = (uri, clientId, clientSecret) => {
    // Update config
    config = {
        ...config,
        API_URI: uri,
        NOTIFY_CLIENTID: clientId,
        NOTIFY_SECRET: clientSecret,
    };

    // Change the api with the new updated config
    createNotifyApi(config);
};
