import React from 'react';
import { useLocation } from 'react-router-dom';
import { Translate } from '@hanssens/ht-translate';

const SidebarLeftItem = (props) => {
    /********************************/
    /* RENDER 
    /********************************/
    // Extract location
    const location = useLocation();

    // Create the base class names
    let classnames = ['nav-item', 'nav-item-content', 'pl-3'];

    // Add additional classnames based on if the current link is active
    if (props.exact) {
        if (location.pathname === props.link) {
            classnames.push('bg-primary', 'text-white', 'active');
        }
    } else {
        if (location.pathname.startsWith(props.link)) {
            classnames.push('bg-primary', 'text-white', 'active');
        }
    }

    // Add additional classnames if toggled
    if (props.toggled) {
        classnames.push('d-none');
    } else {
        classnames.push('d-flex', 'align-items-center');
    }

    return (
        <li onClick={() => props.handleLinkClicked(props.link)} className={classnames.join(' ')}>
            <i className={props.icon} />
            <span className='text-capitalize'>
                <Translate id={props.name} />
            </span>
        </li>
    );
};

export default SidebarLeftItem;
