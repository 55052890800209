import React from 'react';
import { useHistory } from 'react-router-dom';
import { CallbackComponent } from 'redux-oidc';
import userManager from '../../configs/userManager';

const CallbackPage = () => {
    const history = useHistory();

    return (
        <CallbackComponent
            userManager={userManager}
            successCallback={(user) => {
                history.replace(user.state.redirectUrl);
            }}
            errorCallback={(error) => {
                history.replace('/unauthorized');
            }}>
            <div>Redirecting...</div>
        </CallbackComponent>
    );
};

export default CallbackPage;
